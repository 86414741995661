import React, { useEffect, useState } from 'react';
import {
  IonAvatar,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonText,
  ToggleCustomEvent,
} from '@ionic/react';

import { useHistory, useLocation } from 'react-router-dom';
import { bookOutline, colorWandOutline, closeOutline, moonSharp, moonOutline, sunnyOutline, sunnySharp, homeOutline, homeSharp, personOutline } from 'ionicons/icons';
import './Menu.css';
import Create from '../pages/Create';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useTranslation } from 'react-i18next';


interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'menu-overview',
    url: '/stories',
    iosIcon: homeOutline,
    mdIcon: homeOutline
  },
  {
    title: 'menu-make-a-story',
    url: '/create',
    iosIcon: colorWandOutline,
    mdIcon: colorWandOutline
  },
  {
    title: 'menu-profile',
    url: '/profile',
    iosIcon: personOutline,
    mdIcon: personOutline
  },
];

const Menu: React.FC = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const [themeToggle, setThemeToggle] = useState<any>(null);
  const [user, setUser] = useState<any | null>(null);

  // Add or remove the "dark" class on the document body
  const toggleDarkTheme = (shouldAdd: boolean) => {
    document.body.classList.toggle('dark', shouldAdd);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const checkUserAuthenticationStatus = () => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        setUserLoggedIn(!!user);
        if (user) {
          try {
            // Get the logged-in user's document from Firestore
            const userRef = doc(db, 'users', user.uid);
            onSnapshot(userRef, async userDoc => {
              if (userDoc.exists()) {
                if (userDoc.data().darkMode !== undefined) {
                  setThemeToggle(userDoc.data().darkMode);
                  toggleDarkTheme(userDoc.data().darkMode);
                } 
                if (userDoc.data().defaultSystemLanguage) {
                  changeLanguage(userDoc.data().defaultSystemLanguage);
                }
                setUser(userDoc.data());
              } else {
                console.error('User document does not exist in Firestore.');
              }
            })
          } catch (error) {
              console.error('Error fetching user:', error);
          }
        } else {
          setUser(null);
        }
      });

      // The `onAuthStateChanged` function returns an unsubscribe function
      // that can be used to stop listening for changes to the authentication state
      return unsubscribe;
    };

    const unsubscribe = checkUserAuthenticationStatus();
    return () => unsubscribe(); // Unsubscribe when the component unmounts
  }, []);

  const toggleTheme = async (ev: any) => {
    if (user) {
      setThemeToggle(!user.darkMode);
      toggleDarkTheme(!user.darkMode);
      await updateDoc(doc(db, 'users', user.id), {
          darkMode: !user.darkMode,
      });
    } else {
      setThemeToggle(!themeToggle);
      toggleDarkTheme(!themeToggle);
    }
  }


  const handleLogout = async () => {
    // Perform the logout logic here (e.g., using Firebase signOut function)
    const auth = getAuth();
    try {
      await signOut(auth);
      history.push('/'); // Redirect to the login or home page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonMenuToggle>
          <IonButton fill='clear'><IonIcon icon={closeOutline}></IonIcon></IonButton>
        </IonMenuToggle>
        <IonList id="inbox-list">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <IonAvatar style={{ width: '100px', height: '100px' }}>
              <img alt="Little Story logo" src="assets/images/logo-transparent.png" />
            </IonAvatar>
          </div>
          <IonListHeader style={{marginBottom: '30px'}}> <IonText style={{textAlign: 'center', display: 'block', width: '100%', fontFamily: 'Amatic SC'}}>Little Story</IonText> </IonListHeader>
          {/* <IonNote>Crafting Dreams, One Little Story at a Time</IonNote> */}
          {/* <IonNote>{t('menu-text')}</IonNote> */}
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <p>{t(appPage.title)}</p>
                </IonItem>
              </IonMenuToggle>
            )
          })}
          <IonItem onClick={toggleTheme} lines="none" detail={false} button routerDirection="none">
            <IonIcon aria-hidden="true" slot="start" ios={themeToggle ? sunnyOutline : moonOutline} md={themeToggle ? sunnySharp : moonSharp} />
            <p>{themeToggle ? t("menu-light-mode") : t("menu-dark-mode")}</p>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

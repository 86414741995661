import React, { CSSProperties, useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonLoading, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonIcon, IonButton, IonModal, IonText, IonChip, IonInput, IonFooter, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonGrid, IonRow, IonCol, IonSelect, IonSelectOption, ToggleCustomEvent, IonItem, IonToggle, IonAlert, IonAvatar, IonRouterLink, IonList, IonLabel, IonTextarea } from '@ionic/react';
import { getAuth, onAuthStateChanged, signOut, updateEmail } from 'firebase/auth';
import { doc, updateDoc, onSnapshot, setDoc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from '../firebaseConfig';
import { add, calendarOutline, closeOutline, folderOpenOutline, logOutOutline, personOutline, removeCircleOutline, settingsOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import './Profile.css';
import TopShapeDivider from '../components/TopShapeDivider';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import LoginComponent from '../components/LoginComponent';
import SignupComponent from '../components/SignupComponent';

const Welcome: React.FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [user, setUser] = useState<any | null>(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [authUser, setAuthUser] = useState<any | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
    const [newDisplayName, setNewDisplayName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [language, setLanguage] = useState('');
    const [systemLanguage, setSystemLanguage] = useState('');
    const [errorAlert, setErrorAlert] = useState(false); // State variable for error alert
    const [newCharacterName, setNewCharacterName] = useState('');
    const [newCharacterDescription, setNewCharacterDescription] = useState('');

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showSignupModal, setShowSignupModal] = useState(false);

    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    const profileContainerStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
        position: 'relative'
    };
    const profileImageStyle = {
        width: '100%',
        maxWidth: '200px',
        height: 'auto',
        borderRadius: '50%',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
    };

    const manageSubscription: CSSProperties = {
        border: '1px solid var(--ion-color-primary)',
        padding: '16px', 
        borderRadius: '32px',
        cursor: 'pointer',
    };

    const languages = [
        {
            value: 'Danish',
            label: t('profile-settings-story-language-danish'),
            flag: '🇩🇰'
        },
        {
            value: 'English',
            label: t('profile-settings-story-language-english'),
            flag: '🇬🇧'
        },
        {
            value: 'Spanish',
            label: t('profile-settings-story-language-spanish'),
            flag: '🇪🇸'
        },
        {
            value: 'German',
            label: t('profile-settings-story-language-german'),
            flag: '🇩🇪'
        },
        {
            value: 'French',
            label: t('profile-settings-story-language-french'),
            flag: '🇫🇷'
        },
        {
            value: 'Italian',
            label: t('profile-settings-story-language-italian'),
            flag: '🇮🇹'
        },
        {
            value: 'Portuguese',
            label: t('profile-settings-story-language-portuguese'),
            flag: '🇵🇹'
        },
        {
            value: 'Hindi',
            label: t('profile-settings-story-language-hindi'),
            flag: '🇮🇳'
          }

    ]

    const systemLanguages = [
        {
            value: 'da',
            label: t('profile-settings-story-language-danish'),
            flag: '🇩🇰'
        },
        {
            value: 'en',
            label: t('profile-settings-story-language-english'),
            flag: '🇬🇧'
        }

    ]

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const handleLanguageChange = (event: CustomEvent) => {
        setLanguage(event.detail.value as string);
        saveLanguage(event.detail.value as string);
    };

    const handleSystemLanguageChange = (event: CustomEvent) => {
        setSystemLanguage(event.detail.value as string);
        saveSystemLanguage(event.detail.value as string);
        changeLanguage(event.detail.value as string);
    };

    const loadingMessages = [
        "profile-loading-text-1",
        "profile-loading-text-2",
        "profile-loading-text-3",
        "profile-loading-text-4",
        "profile-loading-text-5"
    ]

    async function goToBillingPortal() {
        setShowLoading(true);

        const functions = getFunctions();
        const linkToBillingPortal = httpsCallable(functions, 'linkToBillingPortal');

        linkToBillingPortal({
            returnUrl: window.location.href
        }).then((result: any) => {
            window.location.href = result.data;
        }).catch(error => {
            console.error("Error calling function:", error);
        }).finally(() => {
            setShowLoading(false);
        });
    }

    async function saveName(input: string) {
        await updateDoc(doc(db, 'users', user.id), {
            displayName: newDisplayName,
        });

        // Update the user state with the new display name
        setUser((prevUser: any) => ({
            ...prevUser,
            displayName: newDisplayName,
        }));
    }

    async function saveEmail(input: string) {
        // Update email is firestore auth
        const auth = getAuth();
        if (auth.currentUser) {
            await updateEmail(auth.currentUser, input).then(async () => {
                // Update the user's display name in Firestore
                await updateDoc(doc(db, 'users', user.id), {
                    email: input
                });

                // Update the user state with the new display name
                setUser((prevUser: any) => ({
                    ...prevUser,
                    email: input
                }));
            }).catch((error) => {
                if (error.code === 'auth/email-already-in-use') {
                    setErrorAlert(true);
                    setNewEmail(user.email);
                }
            });
        }
    }

    async function saveLanguage(input: string) {
        await updateDoc(doc(db, 'users', user.id), {
            defaultStoryLanguage: input,
        });

        // Update the user state with the new display name
        setUser((prevUser: any) => ({
            ...prevUser,
            defaultStoryLanguage: input,
        }));
    }

    async function saveSystemLanguage(input: string) {
        await updateDoc(doc(db, 'users', user.id), {
            defaultSystemLanguage: input,
        });

        // Update the user state with the new display name
        setUser((prevUser: any) => ({
            ...prevUser,
            defaultSystemLanguage: input,
        }));
    }

    async function addNewCharacter() {
        const userRef = doc(db, 'users', user.id);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
            const userData = userDoc.data();

            // Initialize the array if it doesn't exist
            const archivedCharacters = userData.archivedCharacters || [];

            // Add the new character to the array
            archivedCharacters.push({
                name: newCharacterName,
                description: newCharacterDescription
            });

            await updateDoc(userRef, { archivedCharacters });
            setNewCharacterName('');
            setNewCharacterDescription('');
        }
    }

    async function deleteCharacter(index: number) {
        const userRef = doc(db, 'users', user.id);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
            const userData = userDoc.data();

            // Initialize the array if it doesn't exist
            const archivedCharacters = userData.archivedCharacters || [];

            // Check if the index is valid
            if (index >= 0 && index < archivedCharacters.length) {
                // Remove the character at the specified index
                archivedCharacters.splice(index, 1);
                await updateDoc(userRef, { archivedCharacters });
            }
        }
    }

    const handleLogout = async () => {
        // Perform the logout logic here (e.g., using Firebase signOut function)
        try {
          await signOut(authUser);
          // reset user state and set values
          setUser(null);
          setAuthUser(null);
          setNewDisplayName('');
          setNewEmail('');
          setNewCharacterName('');
          setNewCharacterDescription('');
          setLanguage('');
          setSystemLanguage('');


          history.push('/stories'); // Redirect to the login or home page after logout
        } catch (error) {
          console.error('Error logging out:', error);
        }
      };

    useEffect(() => {
        const checkUserAuthenticationStatus = () => {
            const auth = getAuth();
            const unsubscribe = onAuthStateChanged(auth, async (user) => {
                setUserLoggedIn(!!user);
                if (user) {
                    setAuthUser(auth);
                    try {
                        // Get the logged-in user's document from Firestore
                        const userRef = doc(db, 'users', user.uid);
                        onSnapshot(userRef, userDoc => {
                            if (userDoc.exists()) {
                                if (userDoc.data().defaultStoryLanguage) {
                                    setLanguage(userDoc.data().defaultStoryLanguage);
                                }
                                if (userDoc.data().defaultSystemLanguage) {
                                    setSystemLanguage(userDoc.data().defaultSystemLanguage);
                                    changeLanguage(systemLanguage);
                                }
                                setNewDisplayName(userDoc.data().displayName);
                                setNewEmail(userDoc.data().email);
                                setUser(userDoc.data());
                            } else {
                                console.error('User document does not exist in Firestore.');
                            }
                        })
                    } catch (error) {
                        console.error('Error fetching user:', error);
                    }
                }
            });

            // The `onAuthStateChanged` function returns an unsubscribe function
            // that can be used to stop listening for changes to the authentication state
            return unsubscribe;
        };

        const unsubscribe = checkUserAuthenticationStatus();
        return () => unsubscribe(); // Unsubscribe when the component unmounts
    }, []);

    const handleLoginClick = () => {
        setShowLoginModal(true);
      };
    
      const handleSignupClick = () => {
        setShowSignupModal(true);
      };
    
      const handleLoginModalDismiss = (input?: string) => {
        setShowLoginModal(false);
        if (input == "signup") {
          handleSignupClick();
        }
      };
    
    const handleSignupModalDismiss = (input?: string) => {
        setShowSignupModal(false);
        if (input == "login") {
          handleLoginClick();
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    <IonTitle>{t('profile-header-title')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
            <TopShapeDivider />
                <div style={profileContainerStyle}>
                    <img
                        style={profileImageStyle}
                        src="assets/images/littleStory.jpg" // Replace this with the path to your big image
                        alt="Welcome"
                    />
                    <h1>{user && user.displayName ? (
                        <> {t('profile-welcome-user-text', { name: user.displayName })}</>
                    ) : (
                        <>{t('profile-welcome-no-user-text')}</>
                    )}</h1>
                </div>
                <div className={`${!userLoggedIn ? 'overlay' : ''}`} onClick={() => {
                    if (!userLoggedIn) {
                        handleSignupClick();
                    }
                }}
                >
                    <IonGrid class="ion-padding">
                        <IonRow class="ion-padding ion-margin-bottom">
                            <IonCol size="12" size-sm="12">
                                <div className="avatar-container">
                                    <IonIcon color='primary-contrast' size='small' icon={personOutline}></IonIcon>
                                </div>
                            </IonCol>
                            <IonCol size="12" size-sm="6">
                                <IonInput
                                    fill="outline"
                                    label={t('profile-user-name-label')}
                                    labelPlacement="floating"
                                    mode='md'
                                    shape='round'
                                    value={newDisplayName}
                                    onInput={(e) => {
                                        setNewDisplayName((e.target as HTMLInputElement).value);
                                    }}
                                    onIonBlur={() => {
                                        saveName(newDisplayName);
                                    }}
                                    disabled={!userLoggedIn}
                                />
                            </IonCol>
                            <IonCol size="12" size-sm="6">
                                <IonInput
                                    fill="outline"
                                    label={t('profile-user-email-label')}
                                    labelPlacement="floating"
                                    mode='md'
                                    shape='round'
                                    value={newEmail}
                                    onInput={(e) => setNewEmail((e.target as HTMLInputElement).value)}
                                    onIonBlur={() => saveEmail(newEmail)}
                                    disabled={!userLoggedIn}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow class="ion-padding ion-margin-bottom">
                            <IonCol size="12">
                                <div className="avatar-container">
                                    <IonIcon color='primary-contrast' size='small' icon={settingsOutline}></IonIcon>
                                </div>
                            </IonCol>
                            <IonCol size="12" size-sm="6">
                                <IonSelect shape='round' label={t('profile-settings-story-language-label')} value={language} labelPlacement="floating" fill='outline' mode='md' onIonChange={handleLanguageChange} interface='popover' disabled={!userLoggedIn}>
                                    {languages.map(lang => (
                                        <IonSelectOption key={lang.value} value={lang.value}>
                                            {lang.flag} {lang.label}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            </IonCol>
                            <IonCol size="12" size-sm="6">
                                <IonSelect shape='round' label={t('profile-settings-system-language-label')} value={systemLanguage} labelPlacement="floating" fill='outline' mode='md' onIonChange={handleSystemLanguageChange} interface='popover' disabled={!userLoggedIn}>
                                    {systemLanguages.map(systemLang => (
                                        <IonSelectOption key={systemLang.value} value={systemLang.value}>
                                            {systemLang.flag} {systemLang.label}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            </IonCol>
                        </IonRow>
                        <IonRow class="ion-padding ion-margin-bottom">
                            <IonCol size="12">
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="avatar-container">
                                        <IonIcon color='primary-contrast' size='small' icon={folderOpenOutline}></IonIcon>
                                    </div>
                                    <p>{t('profile-settings-character-archive-heading')}</p>
                                </span>
                            </IonCol>
                            <IonCol size="12" size-sm="6">
                                <IonInput
                                    fill="outline"
                                    label={t('profile-settings-character-archive-name-label')}
                                    labelPlacement="floating"
                                    mode='md'
                                    shape='round'
                                    value={newCharacterName}
                                    style={{ height: '58px' }}
                                    onInput={(e) => {
                                        setNewCharacterName((e.target as HTMLInputElement).value);
                                    }}
                                    disabled={!userLoggedIn}
                                />
                            </IonCol>
                            <IonCol size="12" size-sm="6">
                                <IonTextarea
                                    shape='round'
                                    style={{ zIndex: 0 }}
                                    fill="outline"
                                    label={t('profile-settings-character-archive-description-label')}
                                    autoGrow={true}
                                    labelPlacement="floating"
                                    value={newCharacterDescription}
                                    onInput={(e) => {
                                        setNewCharacterDescription((e.target as HTMLInputElement).value);
                                    }}
                                    mode='md'
                                    counter={true}
                                    maxlength={150}
                                    counterFormatter={(currentLength) => `${t('create-story-setting-counter', { count: 150 - currentLength })}`}
                                    disabled={!userLoggedIn}
                                >
                                </IonTextarea>
                            </IonCol>
                            {newCharacterName && (
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <IonButton shape="round" onClick={addNewCharacter} style={{ marginLeft: '5px' }}>
                                        <IonIcon slot="end" icon={folderOpenOutline} />
                                        {t('profile-settings-character-archive-add-button')}
                                    </IonButton>
                                </div>
                            )}

                            <IonCol size="12">
                                {user?.archivedCharacters?.length > 0 && (
                                    <>
                                        <p>{t('profile-settings-character-archive-saved-characters')}</p>
                                        <IonRow>
                                            <IonCol class="ion-no-padding">
                                                <IonList>
                                                    {Object.values(user?.archivedCharacters).map((character: any, index) => (
                                                        <IonItem className='item-create' key={index} lines='none' style={user?.archivedCharacters?.length > 1 ? { marginBottom: '10px' } : {}}>
                                                            <IonLabel>{character.name}
                                                                <p>{character.description}</p>
                                                            </IonLabel>
                                                            <IonIcon
                                                                icon={removeCircleOutline}
                                                                slot="end"
                                                                onClick={() => deleteCharacter(index)}
                                                            />
                                                        </IonItem>
                                                    ))}
                                                </IonList>
                                            </IonCol>
                                        </IonRow>
                                    </>
                                )}
                            </IonCol>
                        </IonRow>
                        <IonRow class="ion-padding ion-margin-bottom">
                            <IonCol size="12" size-sm="6">
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="avatar-container">
                                        <IonIcon color='primary-contrast' size='small' icon={calendarOutline}></IonIcon>
                                    </div>
                                    <p>{t('profile-subscription-title')}</p>
                                </span>
                                {
                                    user && user?.subscriptionStatus === 'active' || user?.subscriptionStatus === 'free' ? (
                                        <div>
                                            <p>
                                                {t('profile-subscription-stories-left-1')} <IonText class="story-count-2">{user.maxStories - (user.currentStories || 0)} </IonText> {user.maxStories - user.currentStories === 1 ? t('profile-subscription-stories-left-2-one-left') : t('profile-subscription-stories-left-2')}
                                            </p>
                                            <p>
                                                {t('profile-subscription-will-be')} {user.subscriptionCancelAtPeriodEnd ? t('profile-subscription-cancelled') : t('profile-subscription-renewed')}: {new Date(user.subscriptionCurrentPeriodEnd * 1000).toLocaleDateString([navigator.language, 'en-US'], { month: 'long', day: '2-digit', year: 'numeric' })}
                                            </p>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', ...manageSubscription }} onClick={user.subscriptionStatus !== 'free' ? goToBillingPortal : () => history.push('/subscribe')}>
                                                <p style={{ margin: 0 }}>{user?.subscriptionStatus === "active" ? t('profile-subscription-details', { number: user.maxStories }) : t('profile-subscription-details-free')}</p>
                                                <IonText color={'primary'}>{t('profile-subscription-change')}</IonText>
                                            </div>
                                        </div>
                                    ) : (
                                        userLoggedIn ? (
                                            <IonRouterLink routerLink='/subscribe'>
                                                <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'space-between', ...manageSubscription }}>
                                                    <p style={{ margin: 0 }}>{t('profile-subscription-no-subscription')}</p>
                                                    <IonText color={'primary'}>{t('profile-subscription-change')}</IonText>
                                                </div>
                                            </IonRouterLink>
                                        ) : (
                                            <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'space-between', ...manageSubscription, opacity: 0.5 }}>
                                                <p style={{ margin: 0 }}>{t('profile-subscription-no-subscription')}</p>
                                                <IonText color={'primary'}>{t('profile-subscription-change')}</IonText>
                                            </div>
                                        )
                                    )
                                }
                            </IonCol>
                        </IonRow>
                        <div style={{ textAlign: 'center', marginTop: '50px' }}>
                            <IonButton color='danger' shape='round' onClick={handleLogout} disabled={!userLoggedIn}>
                                <IonIcon slot="start" icon={logOutOutline}></IonIcon>
                                {t('profile-button-log-out')}
                            </IonButton>
                        </div>
                    </IonGrid>
                </div>

                <IonAlert
                    isOpen={errorAlert}
                    onDidDismiss={() => setErrorAlert(false)}
                    header={t('profile-alert-email-used-header')}
                    message={t('profile-alert-email-used-message')}
                    buttons={['OK']}
                />
                <IonLoading isOpen={showLoading} message={t(loadingMessages[Math.floor(Math.random() * loadingMessages.length)])} />
                {/* Login Modal */}
                {showLoginModal && (
                    <IonModal isOpen={true} onDidDismiss={(e:any) => handleLoginModalDismiss(e)}>
                        <LoginComponent isOpen={showLoginModal} onDismiss={(e: any) => handleLoginModalDismiss(e)} />
                    </IonModal>
                )}
                {/* Sign Up Modal */}
                {showSignupModal && (
                    <IonModal isOpen={showSignupModal} onDidDismiss={(e:any) => handleSignupModalDismiss(e)}>
                        <SignupComponent onDismiss={(e: any) => handleSignupModalDismiss(e)} />
                    </IonModal>
                )}
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default Welcome;
import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonButton, IonLabel, IonRange, IonGrid, IonRow, IonCol, IonIcon, IonList, IonItem, IonAlert, IonButtons, IonMenuButton, IonChip, IonLoading, IonSelect, IonSelectOption, IonText, IonModal, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonTextarea } from '@ionic/react';
import { add, chevronBackCircleOutline, chevronForwardCircleOutline, closeOutline, colorWandOutline, refreshCircleOutline, removeCircleOutline, shuffleOutline } from 'ionicons/icons';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useHistory } from 'react-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './Create.css';
import TopShapeDivider from '../components/TopShapeDivider';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import LoginComponent from '../components/LoginComponent';
import SignupComponent from '../components/SignupComponent';

interface Character {
  name: string;
  description?: string;
}

const Create: React.FC = () => {
  const { t } = useTranslation();
  const [storyType, setStoryType] = useState('');
  const [mainCharacter, setMainCharacter] = useState('');
  const [mainCharacterFromArchive, setMainCharacterFromArchive] = useState<Character | null>(null);
  const [gender, setGender] = useState('');
  const [language, setLanguage] = useState('');
  const [age, setAge] = useState(null);
  const [storySetting, setStorySetting] = useState('');
  const [additionalCharacters, setAdditionalCharacters] = useState<string[]>([]);
  const [showAddCharacterAlert, setShowAddCharacterAlert] = useState(false);
  const [newCharacterName, setNewCharacterName] = useState('');
  const [loading, setLoading] = useState(false); // Loading state variable
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userId, setUserId] = useState('');
  const [user, setUser] = useState<any>(null);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  const [exampleSettings, setExampleSettings] = useState<string[]>([]);
  const [exampleCharacters, setExampleCharacters] = useState<string[]>([]);

  const [settingsExampleIndex, setSettingsExampleIndex] = useState(0);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);

  const loadingTexts = [
    "create-loading-text-1",
    "create-loading-text-2",
    "create-loading-text-3",
    "create-loading-text-4",
    "create-loading-text-5"
  ]

  const subscriptionLoadingTexts = [
    "create-subscription-loading-text-1",
    "create-subscription-loading-text-2",
    "create-subscription-loading-text-3",
    "create-subscription-loading-text-4",
    "create-subscription-loading-text-5"
  ]

  const errorTexts = [
    "create-error-text-1",
    "create-error-text-2",
    "create-error-text-3",
    "create-error-text-4",
    "create-error-text-5"
  ];

  const settingExamples = [
    "create-story-setting-examples-1",
    "create-story-setting-examples-2",
    "create-story-setting-examples-3",
    "create-story-setting-examples-4",
    "create-story-setting-examples-5",
    "create-story-setting-examples-6",
    "create-story-setting-examples-7",
    "create-story-setting-examples-8",
    "create-story-setting-examples-9",
    "create-story-setting-examples-10",
    "create-story-setting-examples-11",
    "create-story-setting-examples-12",
    "create-story-setting-examples-13",
    "create-story-setting-examples-14",
    "create-story-setting-examples-15",
    "create-story-setting-examples-16",
    "create-story-setting-examples-17",
    "create-story-setting-examples-18",
    "create-story-setting-examples-19",
    "create-story-setting-examples-20",
    "create-story-setting-examples-21",
    "create-story-setting-examples-22",
    "create-story-setting-examples-23",
    "create-story-setting-examples-24",
    "create-story-setting-examples-25",
    "create-story-setting-examples-26",
    "create-story-setting-examples-27",
    "create-story-setting-examples-28",
    "create-story-setting-examples-29",
    "create-story-setting-examples-30",
    "create-story-setting-examples-31",
    "create-story-setting-examples-32",
  ]

  const characterExamples = [
    "create-additional-characters-examples-1",
    "create-additional-characters-examples-2",
    "create-additional-characters-examples-3",
    "create-additional-characters-examples-4",
    "create-additional-characters-examples-5",
    "create-additional-characters-examples-6",
    "create-additional-characters-examples-7",
    "create-additional-characters-examples-8",
    "create-additional-characters-examples-9",
    "create-additional-characters-examples-10",
    "create-additional-characters-examples-11",
    "create-additional-characters-examples-12",
    "create-additional-characters-examples-13",
    "create-additional-characters-examples-14",
    "create-additional-characters-examples-15",
  ]

  const languages = [
    {
      value: 'Danish',
      label: t('create-language-danish'),
      flag: '🇩🇰'
    },
    {
      value: 'English',
      label: t('create-language-english'),
      flag: '🇬🇧'
    },
    {
      value: 'Spanish',
      label: t('create-language-spanish'),
      flag: '🇪🇸'
    },
    {
      value: 'German',
      label: t('create-language-german'),
      flag: '🇩🇪'
    },
    {
      value: 'French',
      label: t('create-language-french'),
      flag: '🇫🇷'
    },
    {
      value: 'Italian',
      label: t('create-language-italian'),
      flag: '🇮🇹'
    },
    {
      value: 'Portuguese',
      label: t('create-language-portuguese'),
      flag: '🇵🇹'
    },
    {
      value: 'Hindi',
      label: t('create-language-hindi'),
      flag: '🇮🇳'
    }
  ]

  const storyTypes = [
    {
      label: t('create-story-types-1'),
      value: "Bedtime story"
    },
    {
      label: t('create-story-types-2'),
      value: "Educational story"
    },
    {
      label: t('create-story-types-3'),
      value: "Skill-development story"
    },
    {
      label: t('create-story-types-4'),
      value: "Language story"
    },
    {
      label: t('create-story-types-5'),
      value: "Birthday story"
    },
    {
      label: t('create-story-types-6'),
      value: "Love story"
    },
    {
      label: t('create-story-types-7'),
      value: "Spooky story"
    },
    {
      label: t('create-story-types-8'),
      value: "Funny story"
    }
  ]

  // Function to pick a random loading text
  const pickRandomText = (text: any) => {
    const randomIndex = Math.floor(Math.random() * text.length);
    return t(text[randomIndex]);
  };

  async function callOpenAIAPI() {
    const functions = getFunctions();
    const createMainStory = httpsCallable(functions, 'createMainStory', { timeout: 180000 });
    return await createMainStory({ userId, language, mainCharacter, mainCharacterFromArchive, gender, age, storySetting, additionalCharacters, storyType }).then((result: any) => {
      return result;
    }).catch(error => {
      console.error("Error calling function:", error);
    }).finally(() => {
      console.log("All done");
    });
  }

  const handleInputChange = (event: CustomEvent<any>) => {
    setMainCharacter(event.detail.value as string);
  };

  const handleGenderChange = (event: any) => {
    setGender(event.detail.value as string);
  };

  const handleAgeChange = (event: any) => {
    setAge(event.value);
  };

  const handleLanguageChange = (event: CustomEvent) => {
    setLanguage(event.detail.value as string);
  };

  const handleStorySettingChange = (event: CustomEvent) => {
    setStorySetting(event.detail.value as string);
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const promptInput = {
      mainCharacter,
      gender,
      age,
      language,
      storySetting,
      additionalCharacters
    }

    let docId: any;

    try {
      setLoading(true); // Start the loading state
      const aiOutput: any = await callOpenAIAPI();
      docId = aiOutput.data.docId;
      history.push(`/stories/${docId}`);
      if (!mainCharacterFromArchive) {
        let genderTranslated: any = "";
        if (gender === "boy") {
          genderTranslated = t('create-character-gender-boy');
        } else if (gender === "girl") {
          genderTranslated = t('create-character-gender-girl');
        } else if (gender === "non-binary") {
          genderTranslated = t('create-character-gender-non-binary');
        }
        addNewCharacter(mainCharacter, genderTranslated + ", " + age);
      }
      t('create-language-danish')
      // Check if any additional characters were added
      if (additionalCharacters.length > 0) {
        for (const character of additionalCharacters) {
          addNewCharacter("", character);
        }

      }

    } catch (error) {
      console.error('Error storing prompt input:', error);
      setErrorMessage('An error occurred while storing prompt input. Please try again later.');
      setShowAlert(true);
    } finally {
      setLoading(false); // End the loading state
    }
  };

  const handleAddCharacter = () => {
    setShowAddCharacterAlert(true);
  };

  const handleRemoveCharacter = (index: number) => {
    const updatedCharacters = [...additionalCharacters];
    updatedCharacters.splice(index, 1);
    setAdditionalCharacters(updatedCharacters);
  };

  const handleAlertInputChange = (event: any) => {
    setNewCharacterName(event.detail.value as string);
  };

  const handleAlertButtonConfirm = () => {
    if (newCharacterName.trim() !== '') {
      setAdditionalCharacters([...additionalCharacters, newCharacterName]);
      setNewCharacterName('');

    }
    setShowAddCharacterAlert(false);
  };

  const handleAlertButtonCancel = () => {
    setShowAddCharacterAlert(false);
  };

  const openSubscriptionModal = () => {
    setIsSubscriptionModalOpen(true);
  };

  const closeSubscriptionModal = () => {
    setIsSubscriptionModalOpen(false);
  };

  async function goToBillingPortal() {
    setSubscriptionLoading(true);

    const functions = getFunctions();
    const linkToBillingPortal = httpsCallable(functions, 'linkToBillingPortal');

    linkToBillingPortal({
      returnUrl: window.location.href
    }).then((result: any) => {
      window.location.href = result.data;
    }).catch(error => {
      console.error("Error calling function:", error);
    }).finally(() => {
      setSubscriptionLoading(false);
    });
  }

  useEffect(() => {
    const checkUserAuthenticationStatus = () => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        setUserLoggedIn(!!user);
        if (user) {
          setUserId(user.uid);
          try {
            // Get the logged-in user's document from Firestore
            const userRef = doc(db, 'users', user.uid);
            onSnapshot(userRef, userDoc => {
              if (userDoc.exists()) {
                if (userDoc.data().defaultStoryLanguage) {
                  setLanguage(userDoc.data().defaultStoryLanguage);
                }
                setUser(userDoc.data());
                setExampleSettings(settingExamples.sort(() => Math.random() - 0.5));
                setExampleCharacters(characterExamples.sort(() => Math.random() - 0.5).slice(0, 3));
                // setSettingsExampleIndex to a random index in characterExamples array
                setSettingsExampleIndex(Math.floor(Math.random() * characterExamples.length));
              } else {
                console.error('User document does not exist in Firestore.');
              }
            })
          } catch (error) {
            console.error('Error fetching user:', error);
          }
        } else {
          setUserLoggedIn(false);
          setUserId('');
          setUser(null);
        }
      });

      // The `onAuthStateChanged` function returns an unsubscribe function
      // that can be used to stop listening for changes to the authentication state
      return unsubscribe;
    };

    const unsubscribe = checkUserAuthenticationStatus();
    return () => unsubscribe(); // Unsubscribe when the component unmounts
  }, []);

  useEffect(() => {
    const unlisten = history.listen(async () => {
      // Reset all state values to their initial state
      setStoryType('');
      setMainCharacter('');
      setMainCharacterFromArchive(null);
      setGender('');
      setLanguage(user?.defaultStoryLanguage || null);
      setAge(null);
      setStorySetting('');
      setAdditionalCharacters([]);
      setShowAddCharacterAlert(false);
      setNewCharacterName('');
      setLoading(false);
      setShowAlert(false);
      setErrorMessage('');
      setExampleSettings(settingExamples.sort(() => Math.random() - 0.5));
      setExampleCharacters(characterExamples.sort(() => Math.random() - 0.5).slice(0, 3));
    });

    // Cleanup function to stop listening when the component is unmounted
    return () => {
      unlisten();
    };
  }, [history, user, language]); // Listen for changes to the history object

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleSignupClick = () => {
    setShowSignupModal(true);
  };

  const handleLoginModalDismiss = (input?: string) => {
    setShowLoginModal(false);
    if (input == "signup") {
      handleSignupClick();
    }
  };

  const handleSignupModalDismiss = (input?: string) => {
    setShowSignupModal(false);
    if (input == "login") {
      handleLoginClick();
    }
  };

  async function addNewCharacter(name: string, description: string) {
    const userRef = doc(db, 'users', user.id);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Initialize the array if it doesn't exist
      const archivedCharacters = userData.archivedCharacters || [];

      // Add the new character to the array
      const newCharacter = {
        name: name,
        description: description
      }

      // Add the new character to the array if it doesn't already exist
      if (!archivedCharacters.some((character: any) => character.name === name && character.description === description)) {
        archivedCharacters.push(newCharacter);
      }

      await updateDoc(userRef, { archivedCharacters });
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>{t('create-header-title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <TopShapeDivider />
        <div className='top-container'>
          <h1>{t('create-title')}</h1>
          <p>{user?.subscriptionStatus == 'active' || user?.subscriptionStatus == 'free' && (user?.currentStories ?? 0) < user.maxStories ? (<span>
            {t('create-text-part-1')}
            <IonText class='story-count'> {user.maxStories - (user.currentStories ?? 0)} </IonText>
            {user.maxStories - user.currentStories === 1 ? t('create-text-part-2-one-left') : t('create-text-part-2')}
          </span>) : (user?.subscriptionStatus == 'active' || user?.subscriptionStatus == 'free' && user.currentStories === user.maxStories ? (<span>{t('create-all-stories-used')}</span>) : (<span>{t('create-no-active-subscription')}</span>))}</p>
          <div style={{ marginTop: '20px' }}>
            <>
              {user?.subscription && (
                <>
                  {!(user?.subscriptionStatus === 'active' && user?.currentStories !== user?.maxStories) && (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                      <IonButton shape='round' onClick={goToBillingPortal}>
                        {t('create-manage-subscription')}
                      </IonButton>
                    </div>
                  )}
                </>
              )}
              {/* Handle case with old users */}
              {userLoggedIn && !user?.subscription && user?.subscriptionStatus !== 'free' && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <IonButton shape='round' routerLink='/subscribe'>
                    {t('create-start-subscription')}
                  </IonButton>
                </div>
              )}
              {userLoggedIn && user?.subscriptionStatus === 'free' && user.currentStories === user.maxStories && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <IonButton shape='round' routerLink='/subscribe'>
                    {t('create-start-subscription')}
                  </IonButton>
                </div>
              )}
              {!userLoggedIn && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <IonButton shape='round' onClick={handleSignupClick}>
                    {t('create-button-not-logged-in')}
                  </IonButton>
                </div>
              )}
            </>
          </div>
        </div>
        <div className={`${!userLoggedIn ? 'overlay' : ''}`} onClick={() => {
          if (!userLoggedIn) {
            handleSignupClick();
          }
        }}
        >
          <form onSubmit={handleFormSubmit} style={{ position: 'relative' }}>
            <IonGrid class="ion-padding">
              <IonRow class="ion-padding ion-margin-bottom">
                <IonCol size="12">
                  <p>{t('create-character-heading')}</p>
                </IonCol>
                {!mainCharacterFromArchive && (
                  <>
                    <IonCol size="12" size-sm="6">
                      <IonInput fill="outline" label={t('create-character-name-label')} shape='round' labelPlacement="floating" value={mainCharacter} onInput={(e) => setMainCharacter((e.target as HTMLInputElement).value)} required={true} mode='md' disabled={(user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories} />
                    </IonCol>
                    <IonCol size="12" size-sm="3">
                      <IonSelect
                        value={gender}
                        label={t('create-character-gender-label')}
                        labelPlacement="floating"
                        mode="md"
                        fill="outline"
                        shape='round'
                        interface='popover'
                        onIonChange={(e) => handleGenderChange({ detail: { value: e.detail.value } })}
                        disabled={(user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories}
                      >
                        <IonSelectOption value="boy">
                          <IonLabel>{t('create-character-gender-boy')}</IonLabel>
                        </IonSelectOption>
                        <IonSelectOption value="girl">
                          <IonLabel>{t('create-character-gender-girl')}</IonLabel>
                        </IonSelectOption>
                        <IonSelectOption value="non-binary">
                          <IonLabel>{t('create-character-gender-non-binary')}</IonLabel>
                        </IonSelectOption>
                      </IonSelect>
                    </IonCol>
                    <IonCol size="12" size-sm="3">
                      <IonSelect
                        value={age}
                        mode="md"
                        label={t('create-character-age-label')}
                        labelPlacement="floating"
                        fill="outline"
                        shape='round'
                        interface='popover'
                        onIonChange={(e) => handleAgeChange({ value: e.detail.value })}
                        disabled={(user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories}
                      >
                        {Array.from({ length: 101 }, (_, index) => (
                          <IonSelectOption key={index} value={index}>
                            {index}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonCol>
                  </>
                )}
                {mainCharacterFromArchive && (
                  <IonCol>
                    <IonItem className='item-create' lines='none'>
                      <IonLabel>
                        {mainCharacterFromArchive.name}
                        {mainCharacterFromArchive.description && (
                          <p>{mainCharacterFromArchive.description}</p>
                        )}
                      </IonLabel>

                      <IonIcon
                        icon={removeCircleOutline}
                        slot="end"
                        onClick={() => setMainCharacterFromArchive(null)}
                      />
                    </IonItem>
                  </IonCol>

                )}
                {user?.archivedCharacters && user.archivedCharacters.length > 0 && (
                  <IonCol size="12">
                    <p>{t('create-character-or-from-archieve')}</p>
                    <IonSelect
                      value=""
                      label={t('create-character-from-archieve')}
                      labelPlacement="floating"
                      mode="md"
                      fill="outline"
                      shape='round'
                      interface='popover'
                      onIonChange={(e) => {
                        setMainCharacterFromArchive(e.detail.value);
                      }}
                      disabled={(user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories}
                    >
                      {user?.archivedCharacters.map((character: any, index: any) => (
                        <IonSelectOption key={index} value={character}>
                          {character.name && character.description ? character.name + ": " + character.description : character.name && !character.description ? character.name : character.description}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonCol>
                )}
              </IonRow>
              <IonRow class="ion-padding ion-margin-bottom">
                <IonCol size="12">
                  <p>{t('create-story-types-heading')}</p>
                </IonCol>
                <IonCol size="12">
                  <IonSelect
                    value={storyType}
                    label={t('create-story-types-label')}
                    labelPlacement="floating"
                    mode="md"
                    fill="outline"
                    shape='round'
                    interface='popover'
                    onIonChange={(e) => setStoryType(e.detail.value)}
                    disabled={(user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories}
                  >
                    {Object.values(storyTypes).map((type, index) => (
                      <IonSelectOption key={index} value={type.value}>
                        {t(type.label)}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonCol>
                <IonCol size="12">
                  <p>{t('create-story-setting-heading')}</p>
                </IonCol>
                <IonCol size="12">
                  <IonTextarea
                    shape='round'
                    style={{ zIndex: 0 }}
                    fill="outline"
                    label={t('create-story-setting-label')}
                    autoGrow={true}
                    labelPlacement="floating"
                    value={storySetting} onIonChange={handleStorySettingChange}
                    mode='md'
                    counter={true}
                    maxlength={200}
                    counterFormatter={(currentLength) => `${t('create-story-setting-counter', { count: 200 - currentLength })}`}
                    disabled={(user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories}
                  >
                  </IonTextarea>
                </IonCol>
                <IonCol size="12">
                  <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                    <span style={{ flex: 1 }}>
                      {t('create-story-setting-examples-heading')}
                    </span>
                    <span>
                      <IonIcon
                        icon={chevronBackCircleOutline}
                        size='large'
                        onClick={() => (user?.subscriptionStatus === 'active' || user?.subscriptionStatus === 'free' && user.currentStories !== user.maxStories) ? setSettingsExampleIndex((prevIndex) => (prevIndex === 0 ? exampleSettings.length - 1 : prevIndex - 1)) : ""}
                        style={{ cursor: 'pointer', color: 'var(--ion-color-primary)', opacity: (user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories ? '0.5' : '1' }}
                      />
                      <IonIcon
                        icon={chevronForwardCircleOutline}
                        size='large'
                        onClick={() => (user?.subscriptionStatus === 'active' || user?.subscriptionStatus === 'free' && user.currentStories !== user.maxStories) ? setSettingsExampleIndex((prevIndex) => (prevIndex === exampleSettings.length - 1 ? 0 : prevIndex + 1)) : ""}
                        style={{ cursor: 'pointer', marginLeft: '5px', color: 'var(--ion-color-primary)', opacity: (user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories ? '0.5' : '1' }}
                      />
                    </span>
                  </p>
                  {exampleSettings.map((example, index) =>
                    // Render the item only if it's the first one
                    index === settingsExampleIndex && (
                      <div key={index}>
                        <p className='example-setting'>
                          <IonText style={{ paddingTop: '5px', paddingBottom: '5px' }}>{t(example)}</IonText>
                        </p>
                        <div >
                          <IonButton fill='outline' shape="round" onClick={() => (user?.subscriptionStatus === 'active' || user?.subscriptionStatus === 'free' && user.currentStories !== user.maxStories) ? setStorySetting(t(example)) : ""} disabled={(user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories}>
                            {t('create-story-setting-add-button')}
                          </IonButton>
                        </div>
                      </div>
                    )
                  )}
                </IonCol>
              </IonRow>
              <IonRow class="ion-padding ion-margin-bottom">
                <IonCol size="12">
                  <p style={{ marginBottom: 0 }}>{t('create-additional-characters-heading')}</p>
                </IonCol>
                <IonCol>
                  {additionalCharacters.length > 0 && (
                    <IonRow>
                      <IonCol class="ion-no-padding">
                        <IonList >
                          {additionalCharacters.map((character: any, index) => (
                            <IonItem className='item-create' key={index} lines='none' style={additionalCharacters.length > 1 ? { marginBottom: '10px' } : {}}>
                              <IonLabel>
                                {character.name ? character.name : character}
                                {character.description && (
                                  <p>{character.description}</p>
                                )}
                              </IonLabel>
                              <IonIcon
                                icon={removeCircleOutline}
                                slot="end"
                                onClick={() => handleRemoveCharacter(index)}
                              />
                            </IonItem>
                          ))}
                        </IonList>
                      </IonCol>
                    </IonRow>
                  )}
                  <IonRow>
                    <IonCol size="12">
                      <IonButton fill='outline' shape="round" onClick={handleAddCharacter} disabled={(user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories}>
                        <IonIcon slot="end" icon={add} />
                        {t('create-additional-characters-add-button')}
                      </IonButton>
                    </IonCol>
                    {user?.archivedCharacters && user.archivedCharacters.length > 0 && (
                      <IonCol size="12">
                        <p>{t('create-character-or-from-archieve')}</p>
                        <IonSelect
                          value=""
                          label={t('create-character-from-archieve')}
                          labelPlacement="floating"
                          mode="md"
                          fill="outline"
                          shape='round'
                          interface='popover'
                          onIonChange={(e) => {
                            setAdditionalCharacters([...additionalCharacters, e.detail.value])
                          }}
                          disabled={(user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories}
                        >
                          {user?.archivedCharacters.map((character: any, index: any) => (
                            <IonSelectOption key={index} value={character.name ? character : character.description}>
                              {character.name ? character.name : character.description}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonCol>
                    )}
                  </IonRow>
                </IonCol>
                <IonCol size="12">
                  <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 0 }}>
                    <span style={{ flex: 1 }}>
                      {t('create-additional-characters-examples-heading')}
                    </span>
                    <span>
                      <IonIcon
                        icon={refreshCircleOutline}
                        size='large'
                        onClick={() => (user?.subscriptionStatus === 'active' || user?.subscriptionStatus === 'free' && user.currentStories !== user.maxStories) ? setExampleCharacters(characterExamples.sort(() => Math.random() - 0.5).slice(0, 3)) : ""}
                        style={{ cursor: 'pointer', color: 'var(--ion-color-primary)', opacity: (user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories ? '0.5' : '1' }}
                      />
                    </span>
                  </p>
                  {exampleCharacters.map((example, index) => (
                    <IonButton onClick={() => setAdditionalCharacters([...additionalCharacters, t(example)])} fill='outline' key={index} shape='round' style={{ marginRight: '10px' }} disabled={(user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories}>
                      <IonLabel>{t(example)}</IonLabel>
                    </IonButton>
                  ))}
                </IonCol>
              </IonRow>
              <IonRow class="ion-padding ion-margin-bottom">
                <IonCol size="12">
                  <p>{t('create-language-heading')}</p>
                </IonCol>
                <IonCol size="12">
                  <IonSelect shape='round' label={t('create-language-label')} value={language} labelPlacement="floating" fill='outline' mode='md' interface='popover' onIonChange={handleLanguageChange} disabled={(user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories}>
                    {languages.map(lang => (
                      <IonSelectOption key={lang.value} value={lang.value}>
                        {lang.flag} {lang.label}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonCol>
              </IonRow>
              <div style={{ marginTop: '20px' }}>
                <>
                  {(user?.subscriptionStatus === 'active' || user?.subscriptionStatus === 'free') && user.currentStories !== user.maxStories && (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                      <IonButton shape='round' type="submit" disabled={!mainCharacter && !mainCharacterFromArchive || ((user?.subscriptionStatus !== 'active' && user?.subscriptionStatus !== 'free') || user.currentStories === user.maxStories)}>
                        <IonIcon slot="start" icon={colorWandOutline} />
                        {t('create-button-make-a-story')}
                      </IonButton>
                    </div>
                  )}
                </>
              </div>
            </IonGrid>
          </form>
        </div>
        <IonLoading
          isOpen={loading}
          message={pickRandomText(loadingTexts)}>
        </IonLoading>
        <IonLoading
          isOpen={subscriptionLoading}
          message={pickRandomText(subscriptionLoadingTexts)}>
        </IonLoading>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Error'}
          message={pickRandomText(errorTexts)}
          buttons={['OK']}
        />
        <IonAlert
          isOpen={showAddCharacterAlert}
          onDidDismiss={() => {
            setShowAddCharacterAlert(false);
          }}
          header={t('create-additional-characters-alert-header')}
          inputs={[
            {
              name: 'characterName',
              type: 'text',
              placeholder: t('create-additional-characters-alert-placeholder'),
              // value: newCharacterName
            }
          ]}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              handler: handleAlertButtonCancel
            },
            {
              text: t('create-additional-characters-add-button'),
              handler: (res) => {
                if (res.characterName.trim() !== '') {
                  setAdditionalCharacters([...additionalCharacters, res.characterName]);
                  setNewCharacterName('');
                }
              }
            }
          ]}
        />
        {/* Login Modal */}
        {showLoginModal && (
          <IonModal isOpen={true} onDidDismiss={(e: any) => handleLoginModalDismiss(e)}>
            <LoginComponent isOpen={showLoginModal} onDismiss={(e: any) => handleLoginModalDismiss(e)} />
          </IonModal>
        )}
        {/* Sign Up Modal */}
        {showSignupModal && (
          <IonModal isOpen={showSignupModal} onDidDismiss={(e: any) => handleSignupModalDismiss(e)}>
            <SignupComponent onDismiss={(e: any) => handleSignupModalDismiss(e)} />
          </IonModal>
        )}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Create;

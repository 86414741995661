import React, {memo} from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonText, IonCol } from '@ionic/react';
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

interface StoryCardProps {
  story: any;
  listDisplay: boolean;
  handleCardClick: (storyId: string) => void;
  ratingEmojis: string[];
  t: (key: string) => string;
}

const StoryCard: React.FC<StoryCardProps> = memo(({ story, listDisplay, handleCardClick, ratingEmojis, t }) => {
  return (
    <IonCol class='ion-no-padding' sizeXs='12' sizeSm={listDisplay ? '12' : '6'} sizeMd={listDisplay ? '12' : '6'} sizeLg={listDisplay ? '12' : '6'} sizeXl={listDisplay ? '12' : '4'}>
      <div className='ion-padding-horizontal'>
        {listDisplay ? (
          <IonCard onClick={() => handleCardClick(story.id)} style={{ display: 'flex', margin: 0, cursor: 'pointer', marginBottom: '10px' }}>
            <div style={{ flex: 1 }}>
              <IonCardHeader style={{ paddingLeft: '10px' }}>
                <IonCardTitle>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {story.imageUrl && (<img src={story.imageUrl} alt="Card" style={{ borderRadius: '50px', height: '50px', width: '50px' }} loading='lazy' />)}
                    <div style={{ marginLeft: '10px' }}>
                      <h2 style={{ margin: 0, fontSize: '20px' }}>{story?.generated?.title}</h2>
                      <p style={{ margin: 0, fontSize: '12px', color: '#737373' }}>
                        {new Date(story.timestamp).toLocaleDateString([navigator.language, 'en-US'], { month: 'long', day: '2-digit', year: 'numeric' })}
                        {story.userRating && <span style={{ position: 'absolute', right: '0' }}>{t('stories-sort-by-rating')}: {ratingEmojis[story.userRating - 1]}</span>}
                        {story.exampleStory && <span style={{ position: 'absolute', right: '0' }}><IonText color={'primary'}>{t('stories-example-tag-text')}</IonText></span>}
                      </p>
                    </div>
                  </div>
                </IonCardTitle>
              </IonCardHeader>
            </div>
          </IonCard>
        ) : (
          <IonCard onClick={() => handleCardClick(story.id)} style={{ marginRight: '0', marginLeft: '0', cursor: 'pointer' }}>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              {story.imageUrl && (<img className='story-image' src={story.imageUrl} alt="Story image" loading='lazy'/>)}
            </div>
            <div className='ion-padding-horizontal'>
              <h2 style={{ fontSize: '24px' }}>{story?.generated?.title}</h2>
              <p style={{ fontSize: '12px', marginTop: '0', color: '#737373' }}>
                {new Date(story.timestamp).toLocaleDateString([navigator.language, 'en-US'], { month: 'long', day: '2-digit', year: 'numeric' })}
                {story.userRating && <span style={{ float: 'right' }}>{t('stories-sort-by-rating')}: {ratingEmojis[story.userRating - 1]}</span>}
                {story.exampleStory && <span style={{ position: 'absolute', right: '16px' }}><IonText color={'primary'}>{t('stories-example-tag-text')}</IonText></span>}
              </p>
              <p className='summary'>{story?.generated?.summary}</p>
            </div>
          </IonCard>
        )}
      </div>
    </IonCol>
  );
});

export default StoryCard;
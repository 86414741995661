import React, { CSSProperties, useState } from 'react';
import { IonButton, IonInput, IonLabel, IonModal, IonLoading, IonIcon, IonAlert, IonText } from '@ionic/react';
// import { addUserToFirestore } from '../firebaseConfig'; // Assuming you have a function to add users to Firestore
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, signInWithRedirect } from "firebase/auth";
import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { close, logoFacebook, logoGoogle } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

const SignupComponent = ({ onDismiss }: any) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [loading, setLoading] = useState(false); // Loading state variable
  const [errorAlert, setErrorAlert] = useState(false);

  const handleSignup = async (input: any) => {
    // setLoading(true); // Set loading state to true before signup
    const auth = getAuth();
    if (input) {
      let provider: any;
      if (input == "Google") {
        provider = new GoogleAuthProvider();
      } else if (input == "Facebook") {
        provider = new FacebookAuthProvider();
      }
      try {

        await signInWithPopup(auth, provider).then(async (result) => {
        // await signInWithRedirect(auth, provider).then(async (result) => {

          // This gives you a Google Access Token. You can use it to access the Google API.
          // console.log(result);
          let credential: any = null;
          if (input == "Google") {
            credential = GoogleAuthProvider.credentialFromResult(result);
          } else {
            credential = FacebookAuthProvider.credentialFromResult(result);
          }
          // console.log(credential);
          // const token = credential?.accessToken;
          // The signed-in user info.
          const user = result.user;
          const userRef = doc(db, 'users', user.uid);
          const userSnapshot = await getDoc(userRef);
          if (!userSnapshot.exists()) {
            setLoading(true); // Set loading state to true before signup
            await setDoc(userRef, {
              timestamp: Date.now(),
              id: user.uid,
              email: user.email,
              displayName: user.displayName,
              currentStories: 0,
              maxStories: 1,
              subscriptionCancelAtPeriodEnd: false,
              subscriptionCurrentPeriodEnd: Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60, // add seconds for one month
              subscriptionStatus: 'free'
            });
          } else {
            // console.log("Already exists, just log in");
          }
          setLoading(false); // Set loading state to false after signup, whether it succeeded or failed
          onDismiss();
        });
      } catch (error) {
        setErrorAlert(true);
      } finally {
        setLoading(false); // Set loading state to false after signup, whether it succeeded or failed
      }

    } 
    else {
      // const auth = getAuth();
      try {
        setLoading(true); // Set loading state to true before signup
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const userRef = doc(db, 'users', user.uid);
        await setDoc(userRef, {
          timestamp: Date.now(),
          id: user.uid,
          email: email,
          displayName: displayName,
          currentStories: 0,
          maxStories: 1,
          subscriptionCancelAtPeriodEnd: false,
          subscriptionCurrentPeriodEnd: Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60, // add seconds for one month
          subscriptionStatus: 'free'
        });
        onDismiss();
      } catch (error: any) {
        if (error.code === 'auth/email-already-in-use') {
          setErrorAlert(true);
        }
      } finally {
        setLoading(false); // Set loading state to false after signup, whether it succeeded or failed
      }
    }
  };

  const modalContentStyle: CSSProperties = {
    flexDirection: 'column',
    padding: '20px',
    overflowY: 'auto'
  };

  const inputStyle = {
    marginBottom: '15px',
  };

  const buttonStyle = {
    marginBottom: '10px',
    marginTop: '20px',
  };

  return (
    <div style={modalContentStyle}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IonIcon icon={close} color='primary' style={{ fontSize: '24px', cursor: 'pointer' }} onClick={onDismiss} />
      </div>
      <div style={{ marginBottom: '40px' }}>
        <h2 style={{ textAlign: 'center', fontSize: '32px' }} dangerouslySetInnerHTML={{ __html: t('sign-up-modal-title') }}></h2>
      </div>
      <IonInput
        label={t('sign-up-email-label')}
        type="email"
        value={email}
        // onIonChange={(e) => setEmail(e.detail.value!)}
        onInput={(e) => setEmail((e.target as HTMLInputElement).value!)}
        style={inputStyle}
        mode='md'
        labelPlacement="floating"
        fill="outline"
        shape='round'
      />

      <IonInput
        label={t('sign-up-password-label')}
        fill="outline"
        type="password"
        value={password}
        // onIonChange={(e) => setPassword(e.detail.value!)}
        onInput={(e) => setPassword((e.target as HTMLInputElement).value!)}
        style={inputStyle}
        mode='md'
        labelPlacement="floating"
        shape='round'
      />

      <IonInput
        label={t('sign-up-name-label')}
        fill="outline"
        type="text"
        value={displayName}
        // onIonChange={(e) => setDisplayName(e.detail.value!)}
        onInput={(e) => setDisplayName((e.target as HTMLInputElement).value!)}
        style={inputStyle}
        mode='md'
        labelPlacement="floating"
        shape='round'
      />
      <IonButton shape='round' expand="block" onClick={() => handleSignup('')} style={buttonStyle}>
        {t('sign-up-button-sign-up')}
      </IonButton>
      <div className="separator">{t('log-in-or')}</div>
      <div style={{textAlign: 'center', marginTop: '10px'}}>
        <IonButton shape="round" style={{marginRight: '50px'}} onClick={() => handleSignup('Google')}>
          <IonIcon slot="icon-only" icon={logoGoogle}></IonIcon>
        </IonButton>
        <IonButton shape="round" onClick={() => handleSignup('Facebook')}>
          <IonIcon slot="icon-only" icon={logoFacebook}></IonIcon>
        </IonButton>
      </div>
      <p style={{ textAlign: 'center', marginTop: '40px' }}>{t('sign-up-or-already-user')} <IonText style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => onDismiss('login')}>{t('log-in-button-log-in')}</IonText></p>
      <IonLoading
        isOpen={loading}
        message={t('sign-up-loading-text')}
      />
      <IonAlert
        isOpen={errorAlert}
        onDidDismiss={() => setErrorAlert(false)}
        header={t('sign-up-alert-email-used-header')}
        message={t('sign-up-alert-email-used-message')}
        buttons={['OK']}
      />
    </div>
  );
};

export default SignupComponent;

import React, { CSSProperties, useState } from 'react';
import { IonButton, IonInput, IonLabel, IonModal, IonLoading, IonAlert, IonIcon, IonText } from '@ionic/react';
// import { addUserToFirestore } from '../firebaseConfig'; // Assuming you have a function to add users to Firestore
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { close, logoFacebook, logoGoogle } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const LoginComponent = ({ onDismiss }: any) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false); // Loading state variable
  const [resetting, setResetting] = useState(false); // Loading state variable
  const [errorAlert, setErrorAlert] = useState(false); // State variable for error alert
  const [resetAlert, setResetAlert] = useState(false); // State variable for error alert
  

  const handleLogin = async (input: any) => {
    const auth = getAuth();

    if (input) {
      console.log(input);
      let provider: any;
      if (input == "Google") {
        provider = new GoogleAuthProvider();
      } else if (input == "Facebook") {
        provider = new FacebookAuthProvider();
      }
      try {

        await signInWithPopup(auth, provider).then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // console.log(result);
          let credential: any = null;
          if (input == "Google") {
            credential = GoogleAuthProvider.credentialFromResult(result);
          } else {
            credential = FacebookAuthProvider.credentialFromResult(result);
          }
          // console.log(credential);
          // const token = credential?.accessToken;
          // The signed-in user info.
          const user = result.user;
          const userRef = doc(db, 'users', user.uid);
          const userSnapshot = await getDoc(userRef);
          if (!userSnapshot.exists()) {
            setLoading(true); // Set loading state to true before signup
            await setDoc(userRef, {
              timestamp: Date.now(),
              id: user.uid,
              email: user.email,
              displayName: user.displayName,
              currentStories: 0,
              maxStories: 1,
              subscriptionCancelAtPeriodEnd: false,
              subscriptionCurrentPeriodEnd: Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60, // add seconds for one month
              subscriptionStatus: 'free'
            });
          } else {
            console.log("Already exists, just log in");
          }
          onDismiss();
        });
      } catch (error) {
        setErrorAlert(true);
      } finally {
        setLoading(false); // Set loading state to false after signup, whether it succeeded or failed
      }
    } 
    else {
      try {
        setLoading(true); // Set loading state to true before signup
        await signInWithEmailAndPassword(auth, email, password);
      //   const user = userCredential.user;
      //   await addDoc(collection(db, 'users'), {
      //     timestamp: Date.now(),
      //     id: user.uid,
      //     email: email,
      //   });
        onDismiss();
      } catch (error: any) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        setErrorAlert(true); // Show the error alert
      } finally {
        setLoading(false); // Set loading state to false after signup, whether it succeeded or failed
      }
    }
  };

  const handleResetPassword = async () => {
    setLoading(true);
    const auth = getAuth();
    setResetting(true); // Set loading state to true before signup
    try {
      await sendPasswordResetEmail(auth, email);
      
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      setResetAlert(true); // Show the reset alert
    } finally {
      setLoading(false);
    }
  }

  const modalContentStyle: CSSProperties = {
    flexDirection: 'column',
    padding: '20px',
    overflowY: 'auto'
  };

  const inputStyle = {
    marginBottom: '15px',
  };

  const buttonStyle = {
    marginBottom: '10px',
    marginTop: '40px',
  };

  const resetButtonStyle = {
    marginBottom: '10px',
    marginTop: '20px',
    color: 'var(--ion-color-primary-contrast)'
  }

  return (

    <div style={modalContentStyle}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IonIcon icon={close} color='primary' style={{ fontSize: '24px', cursor: 'pointer' }} onClick={onDismiss} />
      </div>
      <div style={{ marginBottom: '40px' }}>
        <h2 style={{ textAlign: 'center', fontSize: '32px' }} dangerouslySetInnerHTML={{ __html:t('log-in-modal-title') }}></h2>
      </div>
      <IonInput
        fill='outline'
        label={t('log-in-email-label')}
        labelPlacement="floating"
        mode='md'
        type="email"
        value={email}
        // onIonChange={(e) => setEmail(e.detail.value!)}
        onInput={(e) => setEmail((e.target as HTMLInputElement).value!)}
        style={inputStyle}
        shape='round'
      />

      <IonInput
        fill='outline'
        label={t('log-in-password-label')}
        labelPlacement="floating"
        mode='md'
        type="password"
        value={password}
        // onIonChange={(e) => setPassword(e.detail.value!)}
        onInput={(e) => setPassword((e.target as HTMLInputElement).value!)}
        style={inputStyle}
        shape='round'
      />
      <IonButton shape='round' expand="block" onClick={() => handleLogin('')} style={buttonStyle}>
        {t('log-in-button-log-in')}
      </IonButton>
      <div style={{ textAlign: 'right', fontSize: '14px' }}>
        <p style={{cursor: 'pointer'}} onClick={handleResetPassword}> {t('log-in-button-reset-password')}</p>
      </div>
      <div className="separator">{t('log-in-or')}</div>
      <div style={{textAlign: 'center', marginTop: '10px'}}>
        <IonButton shape="round" style={{marginRight: '50px'}} onClick={() => handleLogin('Google')}>
          <IonIcon slot="icon-only" icon={logoGoogle}></IonIcon>
        </IonButton>
        <IonButton shape="round" onClick={() => handleLogin('Facebook')}>
          <IonIcon slot="icon-only" icon={logoFacebook}></IonIcon>
        </IonButton>
      </div>
      <p style={{ textAlign: 'center', marginTop: '40px' }}>{t('log-in-or-new-user')} <IonText style={{textDecoration: 'underline', cursor: 'pointer' }} onClick={() => onDismiss('signup')}>{t('sign-up-button-sign-up')}</IonText></p>
      <IonLoading
        isOpen={loading}
        message={resetting ? t('log-in-loading-reset-password') : t('log-in-loading-log-in')}
      />
      <IonAlert
        isOpen={errorAlert}
        onDidDismiss={() => setErrorAlert(false)}
        header={t('log-in-alert-error-header')}
        message={t('log-in-alert-error-message')}
        buttons={['OK']}
      />
      <IonAlert
        isOpen={resetAlert}
        onDidDismiss={() => setResetAlert(false)}
        header={t('log-in-alert-error-reset-password-header')}
        message={t('log-in-alert-error-reset-password-message')}
        buttons={['OK']}
      />
    </div>
  );
};

export default LoginComponent;

// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations for each language
import translationEN from './locales/en/translation.json';
import translationDA from './locales/da/translation.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      da: {
        translation: translationDA,
      },
      // Add more languages as needed
    },
    // lng: 'en', // set the default language
    lng: navigator.language,
    fallbackLng: 'en', // use en if language file not available for the current language
    interpolation: {
      escapeValue: false, // react already safe from xss
    },
  });

export default i18n;
import React from 'react';
import '../App.css'
import './Footer.css'
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { logoFacebook, logoInstagram, logoPinterest, logoTwitter, logoYoutube } from 'ionicons/icons';

const Footer = () => {
    return (
        <div style={{marginTop: '50px', width: '100%'}}>
             <hr style={{borderTop: '1px solid #555555', boxSizing: 'border-box', height: 0, borderColor: '#5555'}}></hr>
            <IonGrid class="ion-padding-horizontal footer-grid">
                <IonRow class='footer ion-text-center ion-text-md-left'>
                    <IonCol size='12' sizeMd='6' class='ion-text-center ion-text-md-left'>
                        <h2 style={{margin: 0}}>Little Story</h2>
                    </IonCol>
                    <IonCol class='ion-text-center ion-text-md-left'>
                        <div className="icon-container">
                            <a style={{color: '#555555'}} href="https://www.youtube.com/@Personalized-Little-Story" target="_blank" rel="noopener noreferrer">
                                <IonIcon size='large' icon={logoYoutube} style={{ marginRight: '20px' }}></IonIcon>
                            </a>
                            <a style={{color: '#555555'}} href="https://www.facebook.com/LittleStoryAppp" target="_blank" rel="noopener noreferrer">
                                <IonIcon size='large' icon={logoFacebook} style={{ marginRight: '20px' }}></IonIcon>
                            </a>
                            <a style={{color: '#555555'}} href="https://www.instagram.com/littlestory.app/" target="_blank" rel="noopener noreferrer">
                                <IonIcon size='large' icon={logoInstagram} style={{ marginRight: '20px' }}></IonIcon>
                            </a>
                            <a style={{color: '#555555'}} href="https://www.pinterest.com/Little_Story" target="_blank" rel="noopener noreferrer">
                                <IonIcon size='large' icon={logoPinterest}></IonIcon>
                            </a>
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow class='footer ion-text-center ion-text-md-left'>
                    {/* <IonCol size='12' sizeMd='6' class='ion-text-center ion-text-md-left'>
                        <p>Copyright © 2024 Little Story. All Rights Reserved.</p>
                    </IonCol> */}
                    {/* <IonCol size='12' sizeMd='6' class='ion-text-center ion-text-md-left'>
                        <div className="icon-container">
                            <span style={{ display: 'flex', justifyContent: 'center' }}>
                                <p style={{ marginRight: '30px' }}>Contact</p>
                                <p style={{ marginRight: '30px' }}>Terms of Use</p>
                                <p>Privacy Policy</p>
                            </span>
                        </div>
                    </IonCol> */}
                </IonRow>
            </IonGrid>
        </div>
    );
};

export default Footer;
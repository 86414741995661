import React, { useEffect, useState, useCallback } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonIcon, IonButton, IonGrid, IonRow, IonModal } from '@ionic/react';
import { collection, onSnapshot, query, where, FieldPath, doc, getDoc, Query } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useHistory } from 'react-router-dom';
import { colorWandOutline} from 'ionicons/icons';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import TopShapeDivider from '../components/TopShapeDivider';
import Footer from '../components/Footer'; 
import './Stories.css';
import { useTranslation } from 'react-i18next';
import LoginComponent from '../components/LoginComponent';
import SignupComponent from '../components/SignupComponent';
import StoryCard from '../components/StoryCard';
import SortAndDisplayControls from '../components/SortAndDisplayControls';

const Stories: React.FC = () => {
  const { t } = useTranslation();
  const [stories, setStories] = useState<any[]>([]); // Array to store fetched stories
  const [sortOption, setSortOption] = useState<string>('timestamp'); // Sort option: 'timestamp' or 'userRating'
  const history = useHistory();
  const ratingEmojis = ['😢', '😞', '😐', '🙂', '😄'];
  const [listDisplay, setListDisplay] = useState(true);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [exampleStories, setExampleStories] = useState<any[]>([]); 

  const [userLoggedInId, setUserLoggedInId] = useState('');

  useEffect(() => {
    // Get the logged-in user's id
    const auth = getAuth();
    // const user = auth.currentUser;
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      const loggedInUserId = user ? user.uid : null;
      setUserLoggedIn(!!user);

      // Create a query to fetch stories where the 'user' field is equal to the logged-in user's id
      const storiesCollectionRef = collection(db, 'stories'); // Reference to the 'stories' collection
      const storiesQuery = query(storiesCollectionRef, where('user', '==', loggedInUserId));

      const specificDocumentIds = [t('stories-example-story-id-1'), t('stories-example-story-id-2'), t('stories-example-story-id-3')];
      const documentRefs = specificDocumentIds.map((docId) => doc(storiesCollectionRef, docId));

      // Fetch documents by their references
      const documentSnapshots = await Promise.all(documentRefs.map((docRef) => getDoc(docRef)));
      const exampleStoriesData = documentSnapshots.map((snapshot) => ({ id: snapshot.id, exampleStory: true, ...snapshot.data(), userRating: null }));

      setExampleStories(exampleStoriesData);
      setStories(exampleStoriesData);

      const unsubscribe = onSnapshot(storiesQuery, (querySnapshot) => {
        const storiesData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const temp = [...storiesData, ...exampleStoriesData];
        if (temp.length > 0) {
          // Sort stories based on the selected sort option
          if (sortOption === 'timestamp') {
            temp.sort((a: any, b: any) => b.timestamp - a.timestamp);
          } else if (sortOption === '-timestamp') {
            temp.sort((a: any, b: any) => a.timestamp - b.timestamp);
          } else if (sortOption === 'alphabetical') {
            temp.sort((a: any, b: any) => {
              const titleA = a?.generated?.title || '';
              const titleB = b?.generated?.title || '';
              return titleA.localeCompare(titleB);
            });
          } else if (sortOption === 'userRating') {
            temp.sort((a: any, b: any) => {
              const userRatingA = a.userRating || 0; // Treat missing userRating as 0
              const userRatingB = b.userRating || 0; // Treat missing userRating as 0
              return userRatingB - userRatingA;
            });
          }
          // Use a Set to track unique keys
          const uniqueKeys = new Set();

          // Filter out duplicates based on unique keys
          const uniqueTemp = temp.filter((story) => {
            if (!uniqueKeys.has(story.id)) {
              uniqueKeys.add(story.id);
              return true;
            }
            return false;
          });
          setStories(uniqueTemp);
        }
      });
      return () => {
        unsubscribe(); // Unsubscribe from the snapshot listener when the component unmounts
        unsubscribeAuth();
      }
    })

  }, [sortOption]);

  const handleCardClick = useCallback((storyId: string) => {
    // Navigate to the Story page with the specific story ID
    history.push(`/stories/${storyId}`);
  }, [history]);

  const handleSortOptionChange = useCallback((option: string) => {
    setSortOption(option);
  },[]);

  const goTo = (path: string) => {
    history.push('/' + path);
  }

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleSignupClick = () => {
    setShowSignupModal(true);
  };

  const handleLoginModalDismiss = (input?: string) => {
    setShowLoginModal(false);
    if (input == "signup") {
      handleSignupClick();
    }
  };

const handleSignupModalDismiss = (input?: string) => {
    setShowSignupModal(false);
    if (input == "login") {
      handleLoginClick();
    }
};

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Little Story</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <TopShapeDivider />
        <div className='top-container'>
          {userLoggedIn ? (
            <div>
              <h1>{t('stories-title')}</h1>
              <p>{t('stories-text')}</p>
              <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <IonButton shape='round' onClick={() => goTo('create')}>
                  <IonIcon slot="start" icon={colorWandOutline} />
                  {t('stories-button-make-a-story')}
                </IonButton>
              </div>
            </div>
          ) : (
            <div>
              <h1>{t('welcome-title')}</h1>
              <p>{t('welcome-text')}</p>
              <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <IonButton shape='round' onClick={handleSignupClick} style={{ marginRight: '20px' }}>
                  {t('welcome-button-text')}
                </IonButton>
              </div>
            </div>
          )}
        </div>
        <SortAndDisplayControls
          sortOption={sortOption}
          setSortOption={handleSortOptionChange}
          listDisplay={listDisplay}
          setListDisplay={setListDisplay}
          t={t}
        />
        <IonGrid>
          <IonRow className='stories'>
            {stories.map((story, index) => (
              <StoryCard
                key={index}
                story={story}
                listDisplay={listDisplay}
                handleCardClick={handleCardClick}
                ratingEmojis={ratingEmojis}
                t={t}
              />
            ))}
          </IonRow>
        </IonGrid>
        {/* Login Modal */}
        {showLoginModal && (
          <IonModal isOpen={true} onDidDismiss={(e: any) => handleLoginModalDismiss(e)}>
            <LoginComponent isOpen={showLoginModal} onDismiss={(e: any) => handleLoginModalDismiss(e)} />
          </IonModal>
        )}
        {/* Sign Up Modal */}
        {showSignupModal && (
          <IonModal isOpen={showSignupModal} onDidDismiss={(e:any) => handleSignupModalDismiss(e)}>
            <SignupComponent onDismiss={(e:any) => handleSignupModalDismiss(e)} />
          </IonModal>
        )}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Stories;

import React, { useEffect, useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonLoading } from '@ionic/react';
import './Subscribe.css';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

declare global {
  namespace JSX { // eslint-disable-line
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const Subscribe: React.FC = () => {

  const { t } = useTranslation();

  const [user, setUser] : any = useState(null);
  const [userData, setUserData] : any = useState(null);

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setUserData((await getDoc(doc(db, 'users', user.uid))).data());
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const loadingMessages = [
    "Rounding up unicorns...",
    "Feeding the code hamsters...",
    "Teaching robots to love...",
    "Warming up the servers...",
    "Baking pixels into cookies..."
  ];

  const [showLoading, setShowLoading] = useState(false);

  async function goToBillingPortal() {
    setShowLoading(true);

    const functions = getFunctions();
    const linkToBillingPortal = httpsCallable(functions, 'linkToBillingPortal');

    linkToBillingPortal({
      returnUrl: window.location.href
    }).then((result:any) => {
      window.location.href = result.data;
    }).catch(error => {
      console.error("Error calling function:", error);
    }).finally(() => {
      setShowLoading(false);
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t('subscribe-title')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="ion-padding"></div>
        {user?.uid && user?.email &&
          // Personal
          // <stripe-pricing-table 
          //   pricing-table-id="prctbl_1PoOW3JQSpMmJLm8fQIGVuFp"
          //   publishable-key="pk_test_51PoKYaJQSpMmJLm8AdmAfzhVq92qIInM8hjYfwyeK2xewpFk7ISMK4148V0c8Bz7G90RMiZk6qGEndKMEbJKZsey00a3CpscrO"
          //   client-reference-id={user.uid}
          //   customer-email={user.email}
          // />
          <stripe-pricing-table 
            pricing-table-id="prctbl_1PqvqWJQSpMmJLm80TyWANnD"
            publishable-key="pk_live_51PoKYaJQSpMmJLm8RC4uKEZLNhTlTXHcsRgbnkYdCCOOgVSRc7ft70n5yXDxtgS7luWrQN0VJy1VlrYY8cftDclZ00j6OLkQyS"
            client-reference-id={user.uid}
            customer-email={user.email}
          />
        }
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Subscribe;

import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './App.css'

/* Theme variables */
import './theme/variables.css';
import Create from './pages/Create';
import Stories from './pages/Stories';
import Story from './pages/Story';
import Welcome from './pages/Welcome';
import Subscribe from './pages/Subscribe';
import Profile from './pages/Profile';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Path to your i18n configuration file

setupIonicReact();

import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const App: React.FC = () => {

  const functions = getFunctions();
  const db = getFirestore();
  const auth = getAuth();
  const storage = getStorage();

  if (window.location.hostname === 'localhost') {
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectStorageEmulator(storage, 'localhost', 9199);
  }

  return (
    <I18nextProvider i18n={i18n}>
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route exact path="/stories" render={() => <Redirect to="/stories" />} />
              <Route path="/" component={Stories} exact={true} />
              <Route path="/stories/:id" component={Story} exact={true} />
              <Route path="/create" component={Create} exact={true} />
              <Route path="/stories" component={Stories} exact={true} />
              <Route path="/subscribe" component={Subscribe} exact={true} />
              <Route path="/profile" component={Profile} exact={true} />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </I18nextProvider>

  );
};

export default App;

import React from 'react';
import { IonSelect, IonSelectOption, IonIcon } from '@ionic/react';
import { listOutline, gridOutline } from 'ionicons/icons';

interface SortAndDisplayControlsProps {
  sortOption: string;
  setSortOption: (option: string) => void;
  listDisplay: boolean;
  setListDisplay: (display: boolean) => void;
  t: (key: string) => string;
}

const SortAndDisplayControls: React.FC<SortAndDisplayControlsProps> = ({ sortOption, setSortOption, listDisplay, setListDisplay, t }) => {
  return (
    <div className="ion-padding-horizontal" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', marginBottom: '10px' }}>
      <IonSelect shape='round' label={t('stories-sort-label')} value={sortOption} labelPlacement="floating" fill='outline' mode='md' interface='popover' style={{ width: '180px' }} onIonChange={(e) => setSortOption(e.detail.value)}>
        <IonSelectOption value="timestamp">{t('stories-sort-by-time-newset')}</IonSelectOption>
        <IonSelectOption value="-timestamp">{t('stories-sort-by-time-oldest')}</IonSelectOption>
        <IonSelectOption value="userRating">{t('stories-sort-by-rating')}</IonSelectOption>
        <IonSelectOption value="alphabetical">{t('stories-sort-by-alphabetical')}</IonSelectOption>
      </IonSelect>
      <div className="toggle-container" style={{ marginBottom: '0px' }}>
        <IonIcon size='large' icon={listDisplay ? gridOutline : listOutline} onClick={() => setListDisplay(!listDisplay)}></IonIcon>
      </div>
    </div>
  );
};

export default SortAndDisplayControls;
